<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-card outlined class="mt-2">
        <v-toolbar flat color="grey lighten-3" height="80">
          <v-list-item class="pl-0 pr-0" two-line>
            <v-row no-gutters align="center">
              <v-col cols="8">
                <v-list-item-content>
                  <v-list-item-title class="title">
                    {{ member.full_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ member.email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-col>
              <v-col cols="4" class="text-right">
                <v-chip
                  label
                  :color="status === 'Active' ? 'success' : 'error'"
                  >{{ status }}</v-chip
                >
              </v-col>
            </v-row>
          </v-list-item>
          <template v-slot:extension>
            <v-tabs dark background-color="grey darken-2">
              <v-tab :to="{ name: 'module-flightmode-members-profile' }"
                >Member&#39;s Details</v-tab
              >
              <v-tab
                :to="{ name: 'module-flightmode-members-profile-creative' }"
                >Creative Profile</v-tab
              >
              <v-tab
                :to="{ name: 'module-flightmode-members-profile-memberships' }"
                >Memberships</v-tab
              >
              <v-tab :to="{ name: 'module-flightmode-members-profile-courses' }"
                >Courses</v-tab
              >
            </v-tabs>
          </template>
        </v-toolbar>
        <v-divider></v-divider>
        <div class="pa-5">
          <router-view v-bind:member="member"></router-view>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Members",
          disabled: false,
          exact: true,
          to: {
            name: "module-flightmode-members",
            params: { id: this.$route.params.id },
          },
        },
        {
          text: "Profile",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    member() {
      return this.$store.state.flightmode.members["member"];
    },

    status() {
      if (this.member.current_memberships.length > 0) {
        return "Active";
      }

      return "Inactive";
    },
  },
};
</script>